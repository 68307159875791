import {
    Box,
    Container,
    Paper,
    Stack,
    FormControlLabel,
    FormLabel,
    FormControl,
    RadioGroup,
    Radio,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Grid from '@mui/material/Unstable_Grid2'
import ComponentStepper from '../components/Stepper'
import ButtonCustom from 'src/components/Common/ButtonCustom'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { adjustmentActions, selectEventType } from '../adjustmentSlice'
import { useEffect, useState } from 'react'
import { CONSTANTS } from 'src/config/constants'

const AdjustmentInit = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const eventTypeSelected = useAppSelector(selectEventType)
    const [listSteps, setListSteps] = useState(['1.予定', '2.候補日時', '3.招待'])

    const [eventType, setEventType] = useState(eventTypeSelected || CONSTANTS.TYPE_ADJUSTMENT.INTERNAL)

    useEffect(() => {
        setEventType(eventTypeSelected || CONSTANTS.TYPE_ADJUSTMENT.INTERNAL)
        changeListSteps(eventTypeSelected || CONSTANTS.TYPE_ADJUSTMENT.INTERNAL)
    }, [eventTypeSelected])

    const changeListSteps = (eventType: number) => {
        if (eventType === CONSTANTS.TYPE_ADJUSTMENT.INTERNAL) {
            setListSteps(['1.予定', '2.候補日時', '3.施設'])
        } else {
            setListSteps(['1.予定', '2.候補日時', '3.招待'])
        }
    }

    return (
        <Container sx={{ p: '0 !important' }}>
            <Box>
                <Grid container spacing={4}>
                    <Grid xs={12} sx={{ mt: '12px' }}>
                        <ComponentStepper activeStep={0} listSteps={listSteps} />
                    </Grid>
                </Grid>
            </Box>
            <Paper elevation={3} sx={styles.paper}>
                <Box component="form" noValidate sx={{ width: '100%' }}>
                    <Grid container spacing={3}>
                        <Grid xs={12}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label" sx={styles.formLabel}>
                                    予定参加者を以下より選択してください
                                </FormLabel>
                                <FormLabel id="demo-radio-buttons-group-label" sx={[styles.formLabel, { marginBottom: '25px', fontWeight: '400', marginTop: '10px' }]}>
                                    ※社内参加者は、下記いずれのケースでも1名もしくは複数名を選択できます
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={CONSTANTS.TYPE_ADJUSTMENT.INTERNAL}
                                    value={eventType}
                                    name="event-type"
                                    onChange={(e) => {
                                        setEventType(parseInt(e.target.value))
                                        changeListSteps(parseInt(e.target.value))
                                    }}
                                >
                                    <FormControlLabel value={CONSTANTS.TYPE_ADJUSTMENT.INTERNAL} control={<Radio />} label="社内のみ" />
                                    <FormControlLabel
                                        value={CONSTANTS.TYPE_ADJUSTMENT.SINGLE_EXTERNAL}
                                        control={<Radio />}
                                        label="社外1名"
                                    />
                                    <FormControlLabel
                                        value={CONSTANTS.TYPE_ADJUSTMENT.MULTIPLE_EXTERNAL}
                                        control={<Radio />}
                                        label="社外複数名"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} sx={{ mt: 2 }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <ButtonCustom
                                    child={'キャンセル'}
                                    onClick={() => {
                                        navigate('/schedule-list')
                                    }}
                                />
                                <ButtonCustom
                                    child={'予定入力'}
                                    isSubmit
                                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: '14px !important' }} />}
                                    onClick={() => {
                                        dispatch(adjustmentActions.setEventType(eventType))
                                        localStorage.setItem('event-type', eventType.toString())
                                        navigate('/adjustment/step1')
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Container>
    )
}

const styles = {
    paper: {
        p: { xs: '20px', sm: '30px', md: 5 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.9)',
        boxShadow: ' 0px 4px 120px rgba(0, 0, 0, 0.25)',
        color: '#000000',
        marginTop: '28px',
        borderRadius: '20px',
        border: '1px solid transparent',
    },
    formLabel: {
        color: '#000000',
        fontSize: '16px',
        fontWeight: 600,
        '&.Mui-focused': {
            color: '#000000',
        },
    },
}

export default AdjustmentInit
