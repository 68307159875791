
import React, { useEffect } from 'react'
import AppRoutes from './AppRoutes'
import { ThemeProvider } from '@mui/system'
import theme from './theme'
import { SnackBarProvider } from './components/Common/SnackBarContext'
import { store } from './app/store'
import { Provider } from 'react-redux'
import "./App.css"

const checkForUpdates = async () => {
    try {
        const response = await fetch('/meta.json', { cache: 'no-store' });
        const meta = await response.json();
        const currentVersion = localStorage.getItem('appVersion') || '0';
        if (meta.version !== currentVersion) {
            localStorage.setItem('appVersion', meta.version);
            if(!window.location.pathname.includes('adjustment/single-external-confirm')){
                const newUrl = window.location.origin + window.location.pathname + '?v=' + Date.now();
                window.location.href = newUrl;
            }
        }
    } catch (error) {
      console.error('Error checking for updates:', error);
    }
  };

function App() {
    useEffect(() => {
        checkForUpdates();
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackBarProvider>
                    <div className="App">
                        <AppRoutes />
                    </div>
                </SnackBarProvider>
            </ThemeProvider>
        </Provider>
    )
}

export default App
