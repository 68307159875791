import { useEffect, useState } from 'react'
import ScheduleSingleExternalConfirmFixed from './singleExternalFixed'
import ScheduleSingleExternalConfirmFluid from './singleExternalFluid'
import { useNavigate, useParams } from 'react-router-dom'
import externalUserApi from 'src/api/externalUserApi'
import { Box } from '@mui/material'
import LinearProgressWithLabel from 'src/components/Common/LinearProgressWithLabel'

const ScheduleSingleExternalConfirm = () => {
    const navigate = useNavigate()
    const { hash } = useParams()
    const [isFluid, setIsFluid] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (hash) {
            validateHash(hash)
        }
    }, [hash])

    const validateHash = async (hash: string) => {
        setIsLoading(true)
        externalUserApi
            .validateHashSingleExternal(hash)
            .then((res: any) => {
                const schedule = res.data.schedule
                if(schedule.period_type === 2){
                    setIsFluid(true)
                }
            }).catch((err: any) => {
                setIsFluid(false)
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const Loading = () => {
        return (
            <Box sx={{
                position: 'relative', 
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box sx={{ width: '200px'}}>
                    <LinearProgressWithLabel value={0} />
                </Box>
            </Box>
        )
    }

    return (
        <>
            {isLoading ? <Loading /> : isFluid ? <ScheduleSingleExternalConfirmFluid /> : <ScheduleSingleExternalConfirmFixed />}
        </>
    )
}

export default ScheduleSingleExternalConfirm

