import { TextField } from '@mui/material'
import { useCallback, useEffect, useRef } from 'react'

export const TextArea = (props: any) => {
    const { error = false, customsx = {} } = props
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const autoResize = useCallback(() => {
        const textarea = textAreaRef.current
        if (textarea) {
            // Reset height trước
            textarea.style.height = '24px'
            
            if (textarea.scrollHeight > 24) {
                const maxHeight = 24 * 10
                const newHeight = Math.min(textarea.scrollHeight, maxHeight)
                textarea.style.height = `${newHeight}px`
            }
        }
    }, [])

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            // Thêm timeout để tránh loop
            setTimeout(() => {
                autoResize()
            }, 0)
        })

        if (textAreaRef.current) {
            resizeObserver.observe(textAreaRef.current)
        }

        return () => {
            resizeObserver.disconnect()
        }
    }, [autoResize])

    useEffect(() => {
        autoResize()
    }, [props.value, autoResize])

    return (
        <TextField
            {...props}
            multiline
            InputProps={{
                ...props.InputProps,
                inputRef: textAreaRef,
                inputProps: {
                    style: { 
                        overflow: 'hidden',
                        resize: 'none',
                    }
                }
            }}
            onChange={(e) => {
                autoResize()
                props.onChange?.(e)
            }}
            fullWidth
            focused
            sx={[
                {
                    fieldset: {
                        border: error ? '1px solid red !important' : '1px solid #666 !important',
                        borderRadius: '15px',
                    },
                    '& ::placeholder': {
                        fontSize: '16px',
                        fontWeight: '400',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    },
                    textarea: {
                        color: '#000000',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '24px',
                        height: '24px',
                        maxHeight: '240px',
                    },
                    '.MuiInputBase-root': {
                        height: 'auto',
                        minHeight: '48px',
                        padding: '12px 14px',
                    },
                    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    {
                        transition: 'all 0s 50000s',
                    },
                    ...customsx,
                }
            ]}
        />
    )
}