import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ScheduleList from './pages/Top/ScheduleList'
import AdminLayout from './layout/Admin'
import { Auth } from './layout/Auth'
import { SignInPage } from './pages/Auth'
import './assets/css/styles.css'
import FinalizeStep1 from './pages/Finalize/finalizeStep1'
import FinalizeStep2 from './pages/Finalize/finalizeStep2'
import FinalizeStep3 from './pages/Finalize/finalizeStep3'
import { AdjustmentStep1, AdjustmentStep2, FacilitySelection } from './pages/adjustment'
import AdjustmentStep3 from './pages/adjustment/pages/adjustmentStep3'
import TopPage from './pages/Top/TopPage'
import { GroupList, GroupAdd } from './pages/Group'
import AdjustmentInit from './pages/adjustment/pages/adjustmentInit'
import ScheduleSingleExternalConfirm from './pages/scheduleConfirm/singleExternal'
import ScheduleConfirm from './pages/scheduleConfirm'
import ScheduleSingleExternalConfirmFixed from './pages/scheduleConfirm/singleExternalFixed'

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Navigate replace to="/top" />} />
            <Route element={<Auth />}>
                <Route path="/signin" element={<SignInPage />} />
            </Route>
            <Route element={<AdminLayout />}>
                <Route path="/top" element={<TopPage />} />
                <Route path="/schedule-list" element={<ScheduleList />} />
                <Route path="/adjustment/init" element={<AdjustmentInit />} />
                <Route path="/adjustment/step1/:sid?" element={<AdjustmentStep1 />} />
                <Route path="/adjustment/step2/:sid" element={<AdjustmentStep2 />} />
                <Route path="/adjustment/step3/:sid" element={<AdjustmentStep3 />} />
                <Route path="/adjustment/facility-selection/:sid" element={<FacilitySelection />} />
                <Route path="/finalize/:sid/step1" element={<FinalizeStep1 />} />
                <Route path="/finalize/:sid" element={<FinalizeStep1 />} />
                <Route path="/finalize/:sid/step2" element={<FinalizeStep2 />} />
                <Route path="/finalize/:sid/step3" element={<FinalizeStep3 />} />
                <Route path="/group/list" element={<GroupList />} />
                <Route path="/group/member-manage/:gid?" element={<GroupAdd />} />
            </Route>
            <Route path="/adjustment/confirm/:hash" element={<ScheduleConfirm />} />
            <Route path="/adjustment/single-external-confirm/:hash/" element={<ScheduleSingleExternalConfirm />} />
        </Routes>
    )
}

export default AppRoutes
