import { ListParams, ListResponse, User } from '../models'
import { get, post, put, del, query_params_string } from './axiosClient'
const scheduleApi = {
    getAll(params: any) {
        const url = '/schedule/getAll'
        return get(url, { params })
    },
    getById(params: any) {
        const url = `/schedule/get`
        return get(url, { params })
    },
    create(data: any) {
        const url = '/schedule/create'
        return post(url, data)
    },
    copy(data: any) {
        const url = '/schedule/clone'
        return post(url, data)
    },
    delete(params: any) {
        const url = `/schedule/delete`
        return post(url, params)
    },
    getCandidateTime(sid: string, data: any) {
        const url = `/adjustment/show/candidate-time/${sid}`
        return post(url, data)
    },
    getCandidateTimeV2(sid: string, params: any, data: any) {
        const url = `/adjustment/v2/show/candidate-time/${sid}?${new URLSearchParams(params).toString()}`
        return post(url, data)
    },
    getCandidateTimeV2ForSingleExternal(sid: string, params: any, data: any) {
        const url = `/adjustment/v2/show/candidate-time-single-external/${sid}?${new URLSearchParams(params).toString()}`
        return post(url, data)
    },
    cancel(params: any) {
        const url = `/schedule/cancel`
        return post(url, params)
    },
    update(params: any) {
        const url = `/schedule/update`;
        return post(url, params)
    },

    updateSearchCondition(params: any) {
        const url = `/schedule/update-search-condition`;
        return post(url, params)
    },

    finalize(sid: any, data: any) {
        const url = `/schedule/finalize/${sid}`
        return put(url, data)
    },

    remindEmail(sid: any, data: any) {
        const url = `/schedule/remind-email/${sid}`
        return post(url, data)
    },

    updateInvitees(data: any) {
        const url = `/schedule/updateInvitees`
        return post(url, data)
    },

    getFilterSchedules(params: any) {
        const url = `/schedule/getFilterSchedules`
        return get(url, { params })
    },

    updateExternalEvent(sid: any, data: any) {
        const url = `/schedule/external-user-event/${sid}`
        return put(url, data)
    },
}
export default scheduleApi