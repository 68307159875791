import {
    Stepper,
    Step,
    StepLabel,
    StepIconProps,
    styled,
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export interface StepperProps {
    activeStep: number | 0;
    listSteps?: string[]
}

export default function ComponentStepper({activeStep, listSteps = ['1.予定', '2.候補日時', '3.招待']}: StepperProps) {
    // const listSteps = [
    //     '1.予定',
    //     '2.候補日時',
    //     '3.調整依頼'
    // ]

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 10,
          left: 'calc(-50% + 10px)',
          right: 'calc(50% + 10px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#444444',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#444444',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: '#444444',
          borderTopWidth: 3,
          borderRadius: 1,
        },
    }));


    const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
        ({ theme, ownerState }) => ({
            color: theme.palette.secondary.main,
            display: 'flex',
            height: '24px',
            alignItems: 'center',
            ...(ownerState.active && {
                width: '24px',
                height: '24px',
                color: theme.palette.secondary.main,
                borderRadius: '50%',
                border: '2px solid #000000',
                zIndex: 20,
            }),
            ...(!ownerState.active && {
                width: '20px',
                height: '20px',
                color: 'black',
            }),
            '& .QontoStepIcon-completedIcon': {
                color: '#000000',
                zIndex: 1,
                fontSize: 18,
                backgroundColor: 'black',
            },
            '& .QontoStepIcon-circle': {
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: 'currentColor',
            },
        }),
    );

    function QontoStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                <div className="QontoStepIcon-circle" />
            </QontoStepIconRoot>
        );
    }

    return (
        <Stepper alternativeLabel activeStep={activeStep || 0} connector={<QontoConnector />}>
            {listSteps.map((label) => (
                <Step key={label}>
                    <StepLabel 
                        sx={{
                            '& .Mui-active': {
                                color: '#000000 !important',
                            },
                            '& .MuiStepLabel-label.Mui-active': {
                                marginTop: '12px',
                            },
                            '& .MuiStepLabel-alternativeLabel': {
                                lineHeight: 1.5,
                                fontWeight: 'bold',
                                color: '#999999',
                            },
                            '& .Mui-completed': {
                                color: '#999999 !important',
                            },
                        }} 
                        StepIconComponent={QontoStepIcon}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}