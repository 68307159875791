import { useAppSelector } from 'src/app/hooks'
import { selectEventType } from '../adjustmentSlice'
import AdjustmentInternalAndMultiExternalStep1 from './adjustmentInternalAndMultiExternalStep1'
import AdjustmentSingleExternalStep1 from './adjustmentSingleExternalStep1'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import scheduleApi from 'src/api/scheduleApi'
import { CONSTANTS } from 'src/config/constants'

interface Schedule {
    type: number;
    data: any;
}

const AdjustmentStep1 = () => {
    const { sid } = useParams()
    const navigate = useNavigate()
    const eventType = useAppSelector(selectEventType)
    const [schedule, setSchedule] = useState<Schedule | null>(null)

    useEffect(() => {
        const fetchSchedule = async () => {
            if (!sid) return
            try {
                const response = await scheduleApi.getById({ id: sid })
                setSchedule(response.data)
            } catch (error) {
                console.error('Failed to fetch schedule:', error)
            }
        }

        fetchSchedule()
    }, [sid])

    useEffect(() => {
        if (!sid && 
            !([CONSTANTS.TYPE_ADJUSTMENT.INTERNAL, CONSTANTS.TYPE_ADJUSTMENT.MULTIPLE_EXTERNAL].includes(eventType)) && 
            eventType !== CONSTANTS.TYPE_ADJUSTMENT.SINGLE_EXTERNAL) {
            navigate('/adjustment/init')
        }
    }, [sid, eventType, navigate])

    const renderContent = () => {
        if (sid) {
            if (!schedule) return null // Loading state
            return [CONSTANTS.TYPE_ADJUSTMENT.INTERNAL, CONSTANTS.TYPE_ADJUSTMENT.MULTIPLE_EXTERNAL].includes(schedule.type)
                ? <AdjustmentInternalAndMultiExternalStep1 type={schedule.type} />
                : <AdjustmentSingleExternalStep1 />
        }

        if ([CONSTANTS.TYPE_ADJUSTMENT.INTERNAL, CONSTANTS.TYPE_ADJUSTMENT.MULTIPLE_EXTERNAL].includes(eventType)) {
            return <AdjustmentInternalAndMultiExternalStep1 type={eventType} />
        }
        if (eventType === CONSTANTS.TYPE_ADJUSTMENT.SINGLE_EXTERNAL) {
            return <AdjustmentSingleExternalStep1 />
        }

        return null
    }

    return <>{renderContent()}</>
}

export default AdjustmentStep1
