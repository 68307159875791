import { CONSTANTS } from "src/config/constants"

import dayjs from "dayjs"

export function formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
    }
    const formattedDate: string = date.toLocaleString('ja-JP', options)
    // Adjust the order of the date components and remove the comma
    const [year, month, dayAndTime] = formattedDate.split('/')
    const [day, time] = dayAndTime.split(' ')
    
    const formattedResult: string = `${year.trim()}/${month.trim()}/${day.trim()} ${time.trim()}`

    return formattedResult
}

export function formatTargetDatePeriod(startTimeString: any, endTimeString: any) {
    const startTime = new Date(startTimeString)
    const endTime = new Date(endTimeString)
    const startDateString = `${startTime.getFullYear()}/${(startTime.getMonth() + 1)
        .toString()
        }/${startTime.getDate().toString()}`

    const endDateString = `${endTime.getFullYear()}/${(endTime.getMonth() + 1).toString()}/${endTime
        .getDate()
        .toString()
        }`

    // if (startDateString === endDateString) {
    //     formattedTimeRange = [
    //         `${startDateString}`,
    //         `${startTime.getHours().toString().padStart(2, '0')}:${startTime
    //             .getMinutes()
    //             .toString()
    //             .padStart(2, '0')} ~ ${endTime.getHours().toString().padStart(2, '0')}:${endTime
    //             .getMinutes()
    //             .toString()
    //             .padStart(2, '0')}`,
    //     ]
    // } else {
        // formattedTimeRange = [`${startDateString}`, `~ ${endDateString}`]
    // }
    const formattedTimeRange = `${startDateString}〜 ${endDateString}`

    return formattedTimeRange
}

export function formatTargetDatePeriod2(startTimeString: any, endTimeString: any) {
    const startTime = new Date(startTimeString)
    const endTime = new Date(endTimeString)
    const startDateString = `${startTime.getFullYear()}/${(startTime.getMonth() + 1)
        .toString()
        }/${startTime.getDate().toString()}`

    const endDateString = `${endTime.getFullYear()}/${(endTime.getMonth() + 1).toString()}/${endTime
        .getDate()
        .toString()
        }`

    if (startDateString == endDateString) {
        return startDateString;
    }

    const formattedTimeRange = `${startDateString}〜 ${endDateString}`

    return formattedTimeRange
}

export function formatDateTimeWithDayOfWeek(arr: any) {
    if (arr.length === 0) return "";

    const date = arr[0].dateTime.split("T")[0].replace(/-/g, "/") + "(" + CONSTANTS.DAY_OF_WEEK[dayjs(arr[0].dateTime).day()] + ")";

    const times = arr
        .map((item: any) => item.dateTime.split("T")[1].slice(0, 5))
        .map((time: any) => `${time}~`)
        .join(` <span style="color: #cfcfcf">/</span> `);

    return [date, times];
}

export function groupByDate(arr: any, scheduleData: any){
    const result: any[] = [];
    const condition_of_internal_user = scheduleData?.condition_of_internal_user
    arr.forEach((item: any) => {
      item.start.dateTime = dayjs(item.start.dateTime).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const date = item.start.dateTime.split("T")[0];
      let existingGroup = result.find((group: any) => group.date === date);
  
      if (!existingGroup) {
        existingGroup = {
          date: date,
          start: [],
          schedule_garoon: [],
        };
        result.push(existingGroup);
      }

      if (dayjs(item.start.dateTime).isBefore(dayjs())) {
        return
      }

      const internalUsers = scheduleData.participant.filter((e: any) => e.user.is_employee)
      const attendees = internalUsers.map((e: any) => {
        return {
            code: e.user.user_name,
        }
      })

      if (condition_of_internal_user === CONSTANTS.CONDITION_OF_INTERNAL_USER.AND) {
        if (item?.schedule_garoon.length > 0) {
          const hasNonFiveScore = item.schedule_garoon.some((schedule: any) => schedule.score !== 5);
          if (hasNonFiveScore) {
            return;
          }
        }
      } else {
        const isAnyAttendeeFree = attendees.some((attendee: any) => 
          !item.schedule_garoon.some((schedule: any) => schedule.code === attendee.code && schedule.score !== 5)
        );
        if (!isAnyAttendeeFree) {
            return
        }
      }
  
      item.start.schedule_garoon = item?.schedule_garoon;
      existingGroup.start.push(item.start);
      existingGroup.schedule_garoon.push(...item.schedule_garoon);
    });
  
    return result.map(({ date, ...rest }) => rest);
  };

  export function groupByDateForEditting(arr: any){
    const result: any[] = [];
    arr.forEach((item: any) => {
      item.start_time = dayjs(item.start_time).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const date = item.start_time.split("T")[0];
      let existingGroup = result.find((group: any) => group.date === date);
  
      if (!existingGroup) {
        existingGroup = {
          date: date,
          start: [],
        };
        result.push(existingGroup);
      }
  
      existingGroup.start.push({dateTime: item.start_time});
    });
  
    return result.map(({ date, ...rest }) => rest);
  };

  export function groupByDateForConfirm(arr: any, condition_of_internal_user: string){
    const result: any[] = [];
    arr.forEach((item: any) => {
      const date = item.start_time.split("T")[0];
      let existingGroup = result.find((group: any) => group.date === date);
  
      if (!existingGroup) {
        existingGroup = {
          date: date,
          adjustment: [],
        };
        result.push(existingGroup);
      }

      if (dayjs(item.start_time).isBefore(dayjs())) {
        return
      }

      if (condition_of_internal_user === CONSTANTS.CONDITION_OF_INTERNAL_USER.AND) {
        const allParticipantsAccepted = item.adjustmentParticipant?.every(
          (participant: any) => participant.status === 5
        );
        if (allParticipantsAccepted) {
          existingGroup.adjustment.push(item);
        }
      } else {
        const hasAcceptedParticipant = item.adjustmentParticipant?.some(
          (participant: any) => participant.status === 5
        );
        if (hasAcceptedParticipant) {
          existingGroup.adjustment.push(item);
        }
      }
  
    });
  
    return result.map(({ date, ...rest }) => rest);
  };

  // input start_time, duration
  // "2024-11-18T05:00:00.000Z", 60
  // output 05:00 - 06:00
  export function getAdjustmentItemTime(start_time: any, duration: any){
    const startTime = dayjs(start_time)
    const endTime = startTime.add(duration, 'minutes')
    return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`
  }